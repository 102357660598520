<template>
  <div class="multi-step-form">
    <div class="progress-bar">
      <div
        data-test-id="current-progress-bar"
        class="current-progress-bar"
        :style="{
          width: progressPercentage
        }"
      ></div>
    </div>
    <div class="multi-step-form-header d-flex justify-content-between">
      <div>
        <div data-test-id="progress-title" class="progress-title">
          Paso {{ current }} de {{ steps }}
        </div>
        <div data-test-id="step-title" class="step-title">
          {{ title }}
        </div>
      </div>
      <div v-show="current > 1">
        <div
          data-test-id="back-button"
          role="button"
          class="back-button"
          @click="$emit('update:current', current - 1)"
        >
          Atrás
        </div>
      </div>
    </div>
    <div class="multi-step-form-body">
      <slot name="subheader"> </slot>
      <div v-for="step in steps" :key="`step-${step}`">
        <slot v-if="current === step" :name="`step-${step}`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiStepForm",

  props: {
    current: {
      type: Number,
      default: 1,
      validator: val => val >= 1
    },
    steps: {
      type: Number,
      default: 2
    },
    title: {
      type: String,
      default: "Formulario"
    }
  },

  computed: {
    progressPercentage() {
      const percertange = (this.current * 100) / this.steps;
      return `${percertange}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
.multi-step-form {
  background: $white;

  .back-button {
    font-weight: 600;
    color: var(--primary);
  }

  .multi-step-form-header {
    padding: 36px 30px 33px 30px;
  }

  .multi-step-form-body {
    padding: 0px 30px 30px 30px;
  }

  .progress-title {
    color: $dim-gray;
    font-size: 12px;
    font-weight: 600;
  }

  .step-title {
    color: $nero;
    font-size: 20px;
    font-weight: 600;
  }

  .progress-bar {
    height: 6px;
    width: 100%;
    background-color: $zircon;

    .current-progress-bar {
      height: 100%;
      background-color: var(--primary);
      transition: width 300ms ease;
    }
  }
}
</style>
