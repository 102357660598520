<template>
  <form-create-client
    selection-disabled
    @close="$router.push({ name: 'profile', tab: 'sucursales' })"
  />
</template>

<script>
import FormCreateClient from "./FormCreateClient";
import * as mutation from "@/store/modules/branch/mutations-types";

export default {
  name: "ClientCreateBranchMobile",

  components: {
    FormCreateClient
  },

  data() {
    return {
      parentCompany: this.$store.state.user.id
    };
  },

  created() {
    this.$store.commit(
      `branch/${mutation.SET_PARENT_COMPANY}`,
      this.parentCompany
    );
  }
};
</script>

<style lang="scss" scoped></style>
