<template>
  <div>
    <z-input-address
      id="address"
      v-model="address.address"
      label="Dirección"
      name="address"
      type="text"
      class="mb-3"
      required
      :allow-save="false"
      @selected="handleAutocompleteAddressChange"
    />
    <z-input
      id="references"
      v-model="references"
      label="Cruces, edificios, etc."
      class="mb-3"
      trim
    />
    <b-form-checkbox
      id="default-address"
      v-model="defaultAddress"
      name="default-address"
    >
      Dirección de origen predeterminada
    </b-form-checkbox>
    <div class="mt-4">
      <z-map
        id="address-map"
        reverse-geocode
        :destinations="destinations"
        @decoded-position="handleAddressChange"
      />
    </div>
    <div class="mt-5">
      <z-button
        variant="secondary"
        class="mr-3"
        size="lg"
        @click="$emit('cancel')"
      >
        Cancelar
      </z-button>
      <z-button
        size="lg"
        :disabled="!isValidForm || creating"
        :loading="creating"
        @click="$emit('accept')"
      >
        Crear
      </z-button>
    </div>
  </div>
</template>

<script>
import ZInputAddress from "@zubut/common/src/components/ZInputAddress";
import ZMap from "@zubut/common/src/components/ZMap";
import * as mutation from "@/store/modules/branch/mutations-types";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "ClientCreateStepThree",

  components: {
    ZInputAddress,
    ZMap
  },

  props: {
    creating: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      destinations: []
    };
  },

  computed: {
    address: {
      get() {
        return this.$store.state.branch.create.address;
      },
      set(val) {
        this.$store.commit(`branch/${mutation.SET_ADDRESS}`, val);
      }
    },
    references: {
      get() {
        return this.$store.state.branch.create.addressReferences;
      },
      set(val) {
        this.$store.commit(`branch/${mutation.SET_ADDRESS_REFERENCES}`, val);
      }
    },
    defaultAddress: {
      get() {
        return this.$store.state.branch.create.defaultAddress;
      },
      set(val) {
        this.$store.commit(`branch/${mutation.SET_DEFAULT_ADDRESS}`, val);
      }
    },
    mapAddress() {
      return this.$store.getters["branch/getMapDestination"];
    },
    validAddress() {
      return this.address?.address?.length > 0;
    },
    isValidForm() {
      return this.validAddress;
    }
  },

  watch: {
    mapAddress() {
      this.destinations = _cloneDeep(this.mapAddress);
    }
  },

  methods: {
    handleAddressChange(address) {
      this.address = { ...address };
    },
    handleAutocompleteAddressChange(address) {
      this.address = {
        address: address.address,
        position: { ...address.position }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/utilities/text-size.scss";

#address-map {
  height: 200px;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
}
</style>
