<template>
  <div class="client-create-step-one">
    <z-input
      id="company-name"
      v-model="companyName"
      :label="companyNameLabel"
      trim
    >
    </z-input>
    <div class="mt-5">
      <z-button
        variant="secondary"
        class="mr-3"
        size="lg"
        @click="$emit('cancel')"
      >
        Cancelar
      </z-button>
      <z-button size="lg" :disabled="!isValidForm" @click="$emit('accept')">
        Siguiente
      </z-button>
    </div>
  </div>
</template>

<script>
import * as mutation from "@/store/modules/branch/mutations-types";

export default {
  name: "ClientCreateStepOne",

  computed: {
    companyName: {
      get() {
        return this.$store.state.branch.create.companyName;
      },
      set(val) {
        this.$store.commit(`branch/${mutation.SET_COMPANY_NAME}`, val);
      }
    },
    companyNameLabel() {
      return "Nombre de la sucursal";
    },
    validCompanyName() {
      if (this.companyName.length > 1) {
        return true;
      }
      return false;
    },
    isValidForm() {
      return this.validCompanyName;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/utilities/text-size.scss";

.client-create-step-one {
  ::v-deep .btn-group-toggle {
    .btn.btn-outline-primary {
      color: $dark;
      border-color: $zircon;

      &:hover {
        color: $white;
        border-color: var(--primary);
      }
    }

    .btn.btn-outline-primary.active {
      color: $white;
      border-color: var(--primary);
    }
  }
}
</style>
