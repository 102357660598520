export function makeShortUUID(id = "") {
  return id.substr(id.length - 6);
}

export function truncate(text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
}

/**
 * Validate that the string has the correct name format.
 * Valid format: Cadena
 * Invalid format: Caden4
 * @param {string} name String to validate.
 */
export function validateName(name) {
  const nameRejectRegexp = /\d/;
  return !nameRejectRegexp.test(name) && name.length > 2;
}

/**
 * Validate that string have the correct email format
 * Valid format: cadena@cadena.co,
 */
export function validateEmail(email) {
  // eslint-disable-next-line
  const mailRegexp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return mailRegexp.test(String(email));
}

/**
 * Validate that string have the correct phone format
 * Valid format: (33) 3761-8690, 3335976089, +52 3335976089
 */
export function validatePhone(phone) {
  // eslint-disable-next-line
  const phoneRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$$/;
  return phoneRegEx.test(phone) && phone.length >= 10;
}
